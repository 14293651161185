import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { IsInPreviewMode } from 'src/modules/helpers/element-helpers';
import { IReportNews, services } from 'src/services';

@customElement('report-news')
export class ReportNews extends LitElement {
  static styles = [
    css`
      .news {
        margin-bottom: 16px;
      }

      .preview {
        &[source] {
         position: relative;
        }

        &[source]:hover {
          outline:black 1px solid;
        }
    
        &[source]:hover::before {
          content: attr(source);
          width:max-content;
          background-color: black;
          outline:black 1px solid;
          color: #fff;
          text-align: center;
          bottom:0;
          right:0;
          padding: 1em;
          position: absolute;
        }
      }
    `
  ];

  @state() reportNews: IReportNews[] = [];
  
  // todo move to context element
  @state() editMode: boolean = false;
  
  connectedCallback() {
    super.connectedCallback();
    this.editMode = IsInPreviewMode(this);
    
    services.report.news().then((reportNews: IReportNews[]) => {
      this.reportNews = reportNews;
    });
  }

  render() {
    var classes= { news: true, preview: this.editMode };
    return html`${this.reportNews?.map((news) =>
      html`<div class=${classMap(classes)} source="${news.name}">${unsafeHTML(news.html)}</div>`
    )}`;
  }
}
